import Vue from 'vue';
import Router from 'vue-router';
import Layout from "../layout/index";
Vue.use(Router);
export var constantRouterMap = [{
  meta: {
    title: 'Sign In',
    noCache: true
  },
  path: '/login',
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/index'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: 'Home',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Personal Center',
    meta: {
      title: 'Personal Center'
    }
  }]
}, {
  path: '/system-message',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/monitor/system-message/add'], resolve);
    },
    name: 'Add System Notification',
    meta: {
      title: 'Add Update',
      noKeepAlive: true
    }
  }, {
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/monitor/system-message/update'], resolve);
    },
    name: 'Edit System Notification',
    meta: {
      title: 'Draft Update',
      noKeepAlive: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/ums/user/update'], resolve);
    },
    name: 'Edit Teacher',
    meta: {
      title: 'Edit Teacher',
      noKeepAlive: true
    }
  }, {
    path: 'specialTeacherMap',
    component: function component(resolve) {
      return require(['@/views/ums/user/specialTeacherMap'], resolve);
    },
    name: 'Targeted Teacher Map',
    meta: {
      title: 'Targeted Teacher Map'
    }
  }, {
    path: 'shieldTeacherMap',
    component: function component(resolve) {
      return require(['@/views/ums/user/shieldTeacherMap'], resolve);
    },
    name: 'Shield Teacher Map',
    meta: {
      title: 'Shield Teacher Map'
    }
  }]
}, {
  path: '/operates',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/operate/faq/update'], resolve);
    },
    name: 'Edit FAQ',
    meta: {
      title: 'Edit FAQ',
      noKeepAlive: true
    }
  }, {
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/operate/faq/add'], resolve);
    },
    name: 'Add FAQ',
    meta: {
      title: 'Add FAQ'
    }
  }]
}, {
  path: '/center',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/ums/center/update'], resolve);
    },
    name: 'Edit Centre',
    meta: {
      title: 'Edit Centre',
      noKeepAlive: true
    }
  }, {
    path: 'boostMap',
    component: function component(resolve) {
      return require(['@/views/ums/center/boostMap'], resolve);
    },
    name: 'Boost Map',
    meta: {
      title: 'Boost Map',
      noKeepAlive: true
    }
  }, {
    path: 'importCentreMap',
    component: function component(resolve) {
      return require(['@/views/ums/center/importCentresMap'], resolve);
    },
    name: 'Import Centre Map',
    meta: {
      title: 'Import Centre Map'
    }
  }]
}, {
  path: '/user-audit',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/ums-audit/user-audit/update'], resolve);
    },
    name: 'Audit Qualified Teacher',
    meta: {
      title: 'Audit Qualified Teacher',
      noKeepAlive: true
    }
  }, {
    path: 'update-unqualified',
    component: function component(resolve) {
      return require(['@/views/ums-audit/user-audit/update-unqualified'], resolve);
    },
    name: 'Audit Unqualified Teacher',
    meta: {
      title: 'Audit Unqualified Teacher',
      noKeepAlive: true
    }
  }, {
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/ums-audit/user-audit/edit'], resolve);
    },
    name: 'Update Teacher',
    meta: {
      title: 'Update Teacher',
      noKeepAlive: true
    }
  }, {
    path: 'pending-qualified',
    component: function component(resolve) {
      return require(['@/views/ums-audit/user-audit/update-pending'], resolve);
    },
    name: 'Audit Teacher',
    meta: {
      title: 'Audit Teacher',
      noKeepAlive: true
    }
  }, {
    path: 'pending-unqualified',
    component: function component(resolve) {
      return require(['@/views/ums-audit/user-audit/update-unqualified-pending'], resolve);
    },
    name: 'Audit Teacher',
    meta: {
      title: 'Audit Teacher',
      noKeepAlive: true
    }
  }]
}, {
  path: '/center-audit',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/ums-audit/center-audit/update'], resolve);
    },
    name: 'Audit Centre',
    meta: {
      title: 'Audit Centre',
      noKeepAlive: true
    }
  }, {
    path: 'edit',
    component: function component(resolve) {
      return require(['@/views/ums-audit/center-audit/edit'], resolve);
    },
    name: 'Update Centre',
    meta: {
      title: 'Update Centre',
      noKeepAlive: true
    }
  }]
}, {
  path: '/order',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'details',
    component: function component(resolve) {
      return require(['@/views/oms/order/details'], resolve);
    },
    name: 'Order details',
    meta: {
      title: 'Order details',
      noKeepAlive: true
    }
  }, {
    path: 'invite',
    component: function component(resolve) {
      return require(['@/views/oms/order/invite'], resolve);
    },
    name: 'Invites records',
    meta: {
      title: 'Invites records'
    }
  }, {
    path: 'invite-details',
    component: function component(resolve) {
      return require(['@/views/oms/order/invite-details'], resolve);
    },
    name: 'Invites details',
    meta: {
      title: 'Invites details',
      noKeepAlive: true
    }
  }, {
    path: 'jobs',
    component: function component(resolve) {
      return require(['@/views/oms/order/jobs'], resolve);
    },
    name: 'Jobs records',
    meta: {
      title: 'Jobs records'
    }
  }, {
    path: 'jobs-details',
    component: function component(resolve) {
      return require(['@/views/oms/order/jobs-details'], resolve);
    },
    name: 'Jobs details',
    meta: {
      title: 'Jobs details',
      noKeepAlive: true
    }
  }, {
    path: 'connectedMap',
    component: function component(resolve) {
      return require(['@/views/oms/order/connectedMap'], resolve);
    },
    name: 'Connected Map',
    meta: {
      title: 'Connected Map'
    }
  }, {
    path: 'connectingMap',
    component: function component(resolve) {
      return require(['@/views/oms/order/connectingMap'], resolve);
    },
    name: 'connecting Map',
    meta: {
      title: 'connecting Map'
    }
  }, {
    path: 'failedMap',
    component: function component(resolve) {
      return require(['@/views/oms/order/failedMap'], resolve);
    },
    name: 'Failed Map',
    meta: {
      title: 'Failed Map'
    }
  }, {
    path: 'ur-free-details',
    component: function component(resolve) {
      return require(['@/views/oms/order/ur-free-details'], resolve);
    },
    name: 'U R Free-details',
    meta: {
      title: 'U R Free-details',
      noKeepAlive: true
    }
  }]
}, {
  path: '/statistic',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'map',
    component: function component(resolve) {
      return require(['@/views/statistics/center/map'], resolve);
    },
    name: 'Statistics Map',
    meta: {
      title: 'Statistics Map'
    }
  }]
}, {
  path: '/content',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/cms/content/add'], resolve);
    },
    name: 'Add Content',
    meta: {
      title: 'Add Content'
    }
  }, {
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/cms/content/update'], resolve);
    },
    name: 'Edit Content',
    meta: {
      title: 'Edit Content'
    }
  }]
}, {
  path: '/email',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'add',
    component: function component(resolve) {
      return require(['@/views/monitor/email/add'], resolve);
    },
    name: 'Add Email Template',
    meta: {
      title: 'Add Email Template',
      noKeepAlive: true
    }
  }, {
    path: 'update',
    component: function component(resolve) {
      return require(['@/views/monitor/email/update'], resolve);
    },
    name: 'Edit Email Template',
    meta: {
      title: 'Edit Email Template',
      noKeepAlive: true
    }
  }]
}];
export default new Router({
  mode: 'hash',
  // base: 'admin',
  // mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});